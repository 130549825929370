import './earnings.scss'
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import DataTable from "../../components/dataTable/DataTable";
import { Column } from "../../types/table.types";
import { formatDateTime, numberWithCommas } from "../../services/utils";
import { categoriesServices } from "../../services/categories.services";
import { CategoryObj } from "../../types/categories.types";
import { Link } from "react-router-dom";
import { earningServices } from "../../services/earningServices";
import { Button, Chip, TextField } from "@mui/material";

import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Tooltip from "@mui/material/Tooltip";

import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Select from "react-select";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ReportIcon from '@mui/icons-material/Report';

//modal 
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import axios from 'axios';
import { log } from 'console';

//modal style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    "flex-direction": 'column',
    gap: '20px'
};
interface responseType {
    id: number;
    is_penalty: boolean;
    penalty_amount: number;
    reason: string;

}
const Earnings = () => {
    const { earnerType } = useParams<string>();


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [penaltyAmount, setPenaltyAmount] = useState('');
    const [reason, setReason] = useState('');
    const [responseMessage, setResponseMessage] = useState<responseType>();
    const [userId, setUserId] = useState<number | null>(null);
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const res = await earningServices.get(
                pageIndex + 1,
                pageSize,
                selectedCategory,
                search,
                startDate,
                endDate,
                earnerType || 'all'
            );
            setRows(res.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const handlePost = async () => {
        const data = {
            penalty_amount: penaltyAmount,
            reason: reason,
        };

        try {
            const response = await axios.post(`https://api.tozauz.uz/api/v1/bank/earning-to-penalty/${userId}/`, data, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}  `,
                },

            });
            setResponseMessage(response.data);
            console.log(response.data, "response");
            handleClose();
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    const startDateDefault = () => {
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date.toISOString().slice(0, 10);
    }

    const endDateDefault = () => {
        let date = new Date();
        return date.toISOString().slice(0, 10);
    }

    const [categories, setCategories] = useState<CategoryObj[]>([]);
    const [rows, setRows] = useState<any>([]);

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [selectedCategory, setSelectedCategory] = useState<any>('');
    const [search, setSearch] = useState<any>('');
    const [startDate, setStartDate] = useState<any>(startDateDefault());
    const [endDate, setEndDate] = useState<any>(endDateDefault());
    const [dataRangeValue, setDataRangeValue] = useState<any>({ value: "1month", label: "Oxirgi oy" });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        categoriesServices.getCategories().then(res => {
            setCategories(res.data);
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        fetchData()
    }, [pageIndex, pageSize, selectedCategory, search, startDate, endDate, earnerType])

    const columns: Column[] = [
        {
            id: 'created_at',
            label: 'Vaqti',
            align: 'left',
            format: (row: any) => formatDateTime(row.created_at),
        },
        {
            id: 'amount',
            label: 'Summa',
            align: 'center',
            format: (row: any) => numberWithCommas(row.amount)
        },
        {
            id: 'tarrif',
            label: 'Tarif',
            align: 'center',
            format: (row: any) => <Chip label={row.tarrif} />
        },
        {
            id: 'box_packet',
            label: 'Box/Paket',
            align: 'center',
            format: (row: any) => {
                if (row.packet === null) {
                    return <Link to={`/boxes/${row.box}`}>
                        <Tooltip title="Qutini ko'rish" arrow>
                            <DeleteSweepIcon style={{ color: "#019109" }} />
                        </Tooltip>
                    </Link>
                }
                return <Tooltip title={row.packet.qr_code} arrow>
                    <Button>
                        <LocalMallIcon
                            style={{ color: "#ff4646" }}
                            onClick={() => {
                                navigator.clipboard.writeText(row.packet.qr_code)
                            }}
                        />
                    </Button>
                </Tooltip>
            }
        },
        {
            id: "penalty",
            label: "Jarima",
            align: "center",
            format: (row: any) => {
                
                if (row.is_penalty) {
                    return row.penalty_amount
                }
                return <Tooltip title="Jarima qo'llash">
                    <Button onClick={() => {
                        setUserId(row.id)
                        handleOpen()
                    }}><ReportIcon style={{ color: "#ff4646" }} /></Button>

                </Tooltip>


            }
        },
        {
            id: "reason",
            label: "Sababi",
            align: "center",
            format: (row: any) => {
                
                if (row.is_penalty) {
                    const shortReason = row.reason.split(' ').length > 2
                        ? row.reason.split(' ').slice(0, 2).join(' ') + '...'
                        : row.reason;
                    return (
                        <Tooltip title={row.reason} arrow> 
                        <span>{shortReason}</span>
                        </Tooltip>
                    )
                }
                return "Jarima qo'llanmadi"


            }
        },
        {
            id: 'user',
            label: 'Foydalanuvchi',
            align: 'center',
            format: (row: any) => (
                <Link style={{ textDecoration: "none", fontWeight: "bold" }} to={`/user-bank/${row.user.id}`}>
                    {row.user.first_name} {row.user.phone_number}
                </Link>
            )
        },
        {
            id: 'role',
            label: 'Rol',
            align: 'left',
            format: (row: any) => {
                if (row.user.role === 'ADMIN') {
                    return <Tooltip title="Administrator" arrow>
                        <AdminPanelSettingsRoundedIcon style={{ color: "#00bb00" }} />
                    </Tooltip>
                } else if (row.user.role === 'EMP') {
                    return <Tooltip title="Hodim" arrow>
                        <EngineeringRoundedIcon style={{ color: "#0000ff" }} />
                    </Tooltip>
                } else if (row.user.role === 'POP') {
                    return <Tooltip title="Aholi" arrow>
                        <PersonRoundedIcon style={{ color: "#af0000" }} />
                    </Tooltip>
                }
            }
        }
    ]


    const handleSearch = (e: any) => {
        setSearch(e.target.value);
        setPageIndex(0);
    }

    const handleCategorySelect = (e: any) => {
        setSelectedCategory(e.value);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPageIndex(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPageSize(+event.target.value);
        setPageIndex(0);
    };

    const handleStartDate = (e: any) => {
        setStartDate(e.target.value);
    }

    const handleEndDate = (e: any) => {
        setEndDate(e.target.value);
    }

    const handleChangeDataRange = (e: any) => {
        if (e.value === "all") {
            setStartDate('');
            setEndDate('');
        }
        if (e.value === "today") {
            setStartDate(endDateDefault());
            setEndDate(endDateDefault());
        }
        if (e.value === "1month") {
            setStartDate(startDateDefault());
            setEndDate(endDateDefault());
        }
        if (e.value === "3month") {
            let date = new Date();
            date.setMonth(date.getMonth() - 3);
            setStartDate(date.toISOString().slice(0, 10));
            setEndDate(endDateDefault());
        }
        setDataRangeValue(e);
    }
    const getRowStyle = (row: any) => {
        return row.is_penalty ? { backgroundColor: '#fff8e1' } : {};
    };
    return (
        <div className="earnings">
            <h2 style={{ marginBottom: "10px" }}>Ishlangan mablag' ({numberWithCommas(rows.amount__sum)} so'm)</h2>
            <Paper>
                <div className={"tableHeader"}>
                    <div className="tableFilter">
                        <TextField
                            label="Qidirish"
                            type="search"
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "5px",
                            }}
                            size={"small"}
                            onChange={handleSearch}
                        />
                        <Select
                            options={
                                [
                                    { value: "", label: "Barcha tariflar" },
                                    ...categories.map((item: any) => {
                                        return { value: item.name, label: item.name }
                                    })
                                ]
                            }
                            defaultValue={{ value: "", label: "Barcha tariflar" }}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isSearchable={false}
                            onChange={(e: any) => {
                                handleCategorySelect(e);
                            }}
                        />
                    </div>
                    <div className="dateFilter">

                        <TextField
                            type="date"
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "5px",
                            }}
                            size={"small"}
                            onChange={handleStartDate}
                            value={startDate}
                        />
                        <SwapHorizIcon />
                        <TextField
                            type="date"
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "5px",
                            }}
                            size={"small"}
                            onChange={handleEndDate}
                            value={endDate}
                        />
                        <Select
                            options={
                                [
                                    { value: "all", label: "Barchasi" },
                                    { value: "today", label: "Bugun" },
                                    { value: "1month", label: "Oxirgi oy" },
                                    { value: "3month", label: "Oxirgi 3 oy" },
                                ]
                            }
                            value={dataRangeValue}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isSearchable={false}
                            onChange={(e: any) => {
                                handleChangeDataRange(e);
                            }}
                        />
                    </div>
                </div>
                <DataTable
                    isLoading={isLoading}
                    columns={columns}
                    rows={rows?.results}
                    total={rows?.count}
                    page={pageIndex}
                    rowsPerPage={pageSize}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    getRowStyle={getRowStyle}
                />
            </Paper>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box>
                        <Box sx={style}>
                            <TextField id="standard-basic" label="Jarima miqdori(so'm)" variant="standard" type='number' value={penaltyAmount} onChange={(e) => setPenaltyAmount(e.target.value)} />
                            <TextField id="standard-basic" label="Jarima sababi" variant="standard" value={reason} onChange={(e) => setReason(e.target.value)} />
                            <Button variant="contained" color="success" onClick={handlePost}>Saqlash</Button>
                        </Box>

                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default Earnings;
