import { Column } from "../../types/table.types";
import DataTable from "../../components/dataTable/DataTable";
import React, { useEffect, useState } from "react";
import { earningServices } from "../../services/earningServices";
import { formatDateTime, numberWithCommas } from "../../services/utils";
import { Tooltip } from "@mui/material";
const UserPenalty = (props: any) => {
   const { userId, setPenaltySumma } = props;
   const [rows, setRows] = useState<any>(undefined);

   const [pageIndex, setPageIndex] = useState(0);
   const [pageSize, setPageSize] = useState(5);

   const [loading, setLoading] = useState(false);
   useEffect(() => {
      setLoading(true)
      earningServices.getUserPenalty(userId, pageIndex + 1, pageSize).then((res) => {
         setRows(res.data)
         setPenaltySumma(res.data.amount__sum)
         setLoading(false)
      })
   }, [userId, pageIndex, pageSize])
   const columns: readonly Column[] = [
      {
         id: "id",
         label: "ID",
      },
      {
         id: "amount",
         label: "Miqdori",
         format: (row: any) => {
            return numberWithCommas(row.amount) + " so'm";
         }
      },
      {
         id: "penalty",
         label: "Jarima ",
         format: (row: any) => {
            return numberWithCommas(row.penalty_amount) + " so'm";
         }
      },
      {
         id: "reason",
         label: "Sababi",
         align: "center",
         format: (row: any) => {


            const shortReason = row.reason.split(' ').length > 2
               ? row.reason.split(' ').slice(0, 2).join(' ') + '...'
               : row.reason;

           return (<Tooltip title={row.reason} arrow>
               <span>{shortReason}</span>
            </Tooltip>)

         }




      },
      {
         id: "created_at",
         label: "Vaqt",
         align: "center",
         format: (row: any) => {
            return formatDateTime(row.created_at);
         }
      },
      {
         id: "tarrif",
         label: "Kategoriya",
         align: "right"
      }
   ]
   const handleChangePage = (event: unknown, newPage: number) => {
      setPageIndex(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setPageSize(+event.target.value);
      setPageIndex(0);
   };
   const getRowStyle = (row: any) => {
      return row.is_penalty ? { backgroundColor: '#fff8e1' } : {};
   };
   return (
      <DataTable
         isLoading={loading}
         total={rows?.count}
         rows={rows?.results}
         columns={columns}
         page={pageIndex}
         rowsPerPage={pageSize}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         getRowStyle={getRowStyle}
      />
   )
}

export default UserPenalty
